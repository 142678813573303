import React, { useEffect, useState } from "react";
import "./AddBanner.css";
import Header from "../../utils/Header";
import ReactToggle from "../../utils/ReactToggle";
import { Button } from "@material-ui/core";
import { imageMaxSize } from "../../utils/maxSize";
import { imageExt } from "../../utils/fileExtension";
import { FilePicker } from "react-file-picker";
import { fileUpload } from "../../utils/fileUpload";
import axios from "../../../Axios";
import { toast, ToastContainer } from "react-toastify";
import { labelValueGenerator } from "../../utils/labelValueGenerator";
import MultiSelectBar from "../../utils/MultiSelectBar";
import * as XLSX from 'xlsx';

function AddBanner({ setOpen, setBannerReload }) {
  const [link, setLink] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [classes, setClasses] = useState([]);
  const [boards, setBoards] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [classNo, setClassNo] = useState([null]);
  const [board, setBoard] = useState(null);
  const [medium, setMedium] = useState(null);
  const [xlsxFile, setXlsxFile] = useState(null);
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    (async () => {
      try {
        const response = await axios({
          url: "/v1/auth/syllabus",
          method: "GET",
        });
        if (response) {
          response.data.grades.unshift("all");
          setClasses(labelValueGenerator(response.data.grades));
          setBoards(labelValueGenerator(response.data.boards));
          setMediums(labelValueGenerator(response.data.medium));
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("p_teacher_auth_token");
          localStorage.removeItem("p_teacher_refresh_token");
          history.push("/login");
        }
      }
    })();
    return ac.abort();
  }, []);

  const readUploadFile = (e) => {
    setXlsxFile(e)
    if (e) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            setJsonData(json);
        };
        reader.readAsArrayBuffer(e);
    }
}


  const handleSave = async () => {
    let grades = classNo?.map((item) => item?.value);
    let boards = board?.map((item) => item?.value);
    let mediums = medium?.map((item) => item?.value);

    const data = {
      imageUrl:
        typeof imageUrl === "string" ? imageUrl : await fileUpload(imageUrl),
      link,
      grades,
      boards,
      mediums,
      startDate: "not required",
      endDate: "not required",
      enabled,
      users: jsonData
    };

    console.log(data)
    try {
      const result = await axios({
        method: "post",
        url: "/v1/auth/media?mediaType=Banner",
        data: data,
      });
      if (result) {
        toast.success("Banner Saved");
        setTimeout(() => {
          setOpen(false);
          setBannerReload(true);
        }, 3000);
      }
    } catch (e) {
      toast.error("error saving the banner");
    }
  };

  return (
    <div className="addBanner">
      <Header title="Add Banner" />
      <div className="addBannerContainer">
        <div className="mockTestContainer__top">
          {inputContainer("Link", link, setLink)}
        </div>
        <div className="mockTestContainer__top">
          <div className="classSelector">
            {multiSelectContainer("Grades", classes, classNo, setClassNo)}
            {multiSelectContainer("Boards", boards, board, setBoard)}
            {multiSelectContainer("Mediums", mediums, medium, setMedium)}
          </div>
          <div className="mockTestContainer__top">
            {fileContainer("Upload Image", "Image", imageUrl, setImageUrl)}
          </div>
        </div>
        <div className="mockTestContainer__top">
            {fileContainer("Upload Users", "Excel", xlsxFile, readUploadFile)}
          {ReactToggle("Enable Banner", enabled, setEnabled)}
        </div>
      </div>
      <Button onClick={() => handleSave()}>Save</Button>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AddBanner;

const inputContainer = (title, value, setValue) => (
  <div className="title">
    <label>{title}</label>
    <input
      type="text"
      placeholder={`Add ${title} about content`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);

const fileContainer = (title, type, value, setValue) => (
  <div className="fileContainer">
    <p>{title}</p>
    <div>
      <FilePicker
        maxSize={imageMaxSize}
        extensions={imageExt}
        onChange={(FileObject) => setValue(FileObject)}
        onError={(errMsg) => alert(errMsg)}
      >
        <button>
          <i className="fas fa-cloud-upload-alt"></i>Upload {type}
        </button>
      </FilePicker>
    </div>
    {value && <p>{value.name}</p>}
  </div>
);

export const multiSelectContainer = (
  title,
  selectData,
  value,
  setSelectData
) => {
  return (
    <div className="selectClass123">
      <p>{title}</p>
      <MultiSelectBar
        data={selectData}
        value={value}
        setValue={setSelectData}
      />
    </div>
  );
};
