/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, Suspense, lazy } from "react";

import "./App.css";
import "./Components/multiple choice MA/MultipleChoiceMA.css";
import { Switch, Route } from "react-router-dom";
const MatchFollowing = lazy(() =>
  import("./Components/match following/MatchFollowing")
);
const DragNdDrop = lazy(() => import("./Components/drag and drop/DragNdDrop"));
const MultipleChoiceMA = lazy(() =>
  import("./Components/multiple choice MA/MultipleChoiceMA")
);
const ArrangeNumbers = lazy(() =>
  import("./Components/arrange numbers/ArrangeNumbers")
);
const FillInBlanks = lazy(() =>
  import("./Components/fill in the blanks/FillInBlanks")
);
const LoginPage = lazy(() => import("./Components/login page/LoginPage"));
const Subjects = lazy(() => import("./Components/class subjects/Subjects"));
const ClassSelection = lazy(() => import("./Components/DashBoard/DashBoard"));
const SelectSyllabus = lazy(() =>
  import("./Components/SelectGrade/SelectSyllabus")
);
const BoardSelection = lazy(() =>
  import("./Components/board selection/BoardSelection")
);
const Units = lazy(() => import("./Components/unit selection/Units"));
const Chapters = lazy(() => import("./Components/chapters/Chapters"));
const SubChapters = lazy(() => import("./Components/sub chapters/SubChapter"));
const SelectActivity = lazy(() =>
  import("./Components/select activity/SelectActivity")
);
import PrivateRoute from "./Components/routing/PrivateRoute";
import { useHistory } from "react-router-dom";
const AddActivityLink = lazy(() =>
  import("./Components/add activity link/AddActivityLink")
);
const ClassMedium = lazy(() =>
  import("./Components/medium selection/ClassMedium")
);
const ArrangeUnits = lazy(() =>
  import("./Components/re_arrange_units/ArrangeUnits")
);
const ArrangeTopics = lazy(() =>
  import("./Components/re_arrange_topics/ArrangeTopics")
);
const ArrangeChapters = lazy(() =>
  import("./Components/re_arrange_chapters/ArrangeChapters")
);
const MockTest = lazy(() => import("./Components/MockTest/MockTest"));

const UploadContent = lazy(() => import("./Components/UploadContent/index"));
const LiveClass = lazy(() => import("./Components/live_class/index"));
const UploadMockTest = lazy(() =>
  import("./Components/MockTest/UploadMockTest/UploadMockTest")
);
const MockFillInBlanks = lazy(() =>
  import("./Components/MockTest/Activities/fill in the blanks/FillInBlanks")
);
const MatchTheFollowing = lazy(() =>
  import("./Components/MockTest/Activities/match following/MatchFollowing")
);
const MockMcq = lazy(() =>
  import("./Components/MockTest/Activities/MultipleChoice/MultipleChoice")
);
const MockClassification = lazy(() =>
  import("./Components/MockTest/Activities/drag and drop/DragNdDrop")
);
const MockArrAange = lazy(() =>
  import("./Components/MockTest/Activities/arrange numbers/ArrangeNumbers")
);
const Notifications = lazy(() =>
  import("./Components/Notifications/Notifications")
);
// const Banner = lazy(() => "./Components/Banners/Banner");
import Banner from './Components/Banners/Banner'
const MultipleChoice = lazy(() =>
  import("./Components/MultipleChoice/MultipleChoice")
);
import instance from "./Axios";
import axios from "axios";
import { useDispatch } from "react-redux";
const ContentDashboard = lazy(() =>
  import("./Components/ContentDashboard/ContentDashboard")
);
const ConsumerPortalDashboard = lazy(() =>
  import("./Components/ConsumerPortalDashboard/ConsumerPortalDashboard")
);
const StudentList = lazy(() => import("./Components/Students/StudentList"));
const LssCourse = lazy(() => import("./Components/LssCourse"));
const VavCategories = lazy(() =>
  import("./Components/VavCategories/VavCategories")
);
const LssUnits = lazy(() =>
  import("./Components/LssCourse/DisplayUnits/DisplayUnits")
);
const LssSubjects = lazy(() => import("./Components/LssCourse/LssSubjects"));
const LssChapters = lazy(() => import("./Components/LssCourse/Chapters"));
const LssTopics = lazy(() => import("./Components/LssCourse/Topics"));
const Queries = lazy(() => import("./Components/Queries"));
const Schools = lazy(() => import("./Components/Schools"));
const Transactions = lazy(() => import("./Components/Transaction"));
const PaidviaCash = lazy(() => import("./Components/PaidviaCash/PaidviaCash"));
const Coupon = lazy(() => import("./Components/Coupons"));
const Otp = lazy(() => import("./Components/Otp"));
const Stories = lazy(() => import("./Components/Stories"));
const Scopes = lazy(() => import("./Components/Scopes/Scopes"));

const actionLogoutDispatcher = (dispatch) => ({
  setLogout: (logout) =>
    dispatch({
      type: "USER_LOGOUT",
      payload: logout,
    }),
});

function App() {
  const { setLogout } = actionLogoutDispatcher(useDispatch());

  function token() {
    return localStorage.getItem("p_teacher_auth_token");
  }

  instance.interceptors.request.use(
    async (config) => {
      config.headers.authorization = `Bearer ${token()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  function refreshToken() {
    return localStorage.getItem("p_teacher_refresh_token");
  }

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          let result = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/admin/refresh-token`,
            {
              refreshToken: refreshToken(),
            }
          );
          if (result.status === 200) {
            const { accessToken, refreshToken } = result.data.data;
            localStorage.setItem("p_teacher_auth_token", accessToken);
            localStorage.setItem("p_teacher_refresh_token", refreshToken);
            originalRequest.headers.authorization = `Bearer ${accessToken}`;
            return axios(originalRequest);
          }
        } catch (err) {
          if (err.response.status === 401) {
            setLogout(false);
            localStorage.removeItem("p_teacher_auth_token");
            localStorage.removeItem("p_teacher_refresh_token");
            history.push("/login");
          }
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );
  const authToken = localStorage.getItem("p_teacher_auth_token");
  const history = useHistory();
  useEffect(() => {
    if (!authToken) {
      history.push("/login");
    }
  }, [authToken, history]);

  return (
    <div className="App">
      <Switch>
        <Suspense fallback={<div className="sweet-loading">Loading...</div>}>
          <PrivateRoute exact path="/dashboard" component={ClassSelection} />
          <PrivateRoute exact path="/content" component={ContentDashboard} />
          <PrivateRoute
            exact
            path="/consumer-portal"
            component={ConsumerPortalDashboard}
          />
          <PrivateRoute exact path="/syllabus" component={SelectSyllabus} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/otp" component={Otp} />
          <Route exact path="/" component={Scopes} />

          <PrivateRoute
            exact
            path="/boardselection"
            component={BoardSelection}
          />

          <PrivateRoute exact path="/medium" component={ClassMedium} />

          <PrivateRoute exact path="/subjects" component={Subjects} />

          <PrivateRoute exact path="/units" component={Units} />

          <PrivateRoute exact path="/chapters" component={Chapters} />

          <PrivateRoute exact path="/subchapters" component={SubChapters} />

          <PrivateRoute
            exact
            path="/selectactivity"
            component={SelectActivity}
          />

          <PrivateRoute exact path="/uploadSection" component={UploadContent} />

          <PrivateRoute exact path="/scheduleLiveClass" component={LiveClass} />

          <PrivateRoute
            exact={true}
            path="/matchfollowing"
            component={MatchFollowing}
          />

          <PrivateRoute
            exact={true}
            path="/draganddrop"
            component={DragNdDrop}
          />

          <PrivateRoute
            exact={true}
            path="/multichoicemultianswers"
            component={MultipleChoiceMA}
          />

          <PrivateRoute
            exact={true}
            path="/multiplechoice"
            component={MultipleChoice}
          />

          <PrivateRoute exact={true} path="/mockTest" component={MockTest} />

          <PrivateRoute
            exact={true}
            path="/addMockTest"
            component={UploadMockTest}
          />

          <PrivateRoute
            exact={true}
            path="/arrangenumbers"
            component={ArrangeNumbers}
          />

          <PrivateRoute exact={true} path="/lssCourse" component={LssCourse} />
          <PrivateRoute
            exact={true}
            path="/lssCourse/subjects"
            component={LssSubjects}
          />
          <PrivateRoute
            exact={true}
            path="/lssCourse/units/:subject"
            component={LssUnits}
          />
          <PrivateRoute
            exact={true}
            path="/lssCourse/chapter/:unitId"
            component={LssChapters}
          />
          <PrivateRoute
            exact={true}
            path="/lssCourse/topics"
            component={LssTopics}
          />

          <PrivateRoute
            exact={true}
            path="/fillinblanks"
            component={FillInBlanks}
          />

          <PrivateRoute
            exact={true}
            path="/addActivityLink"
            component={AddActivityLink}
          />

          <PrivateRoute
            exact={true}
            path="/arrangeUnits"
            component={ArrangeUnits}
          />

          <PrivateRoute
            exact={true}
            path="/arrangeChapters"
            component={ArrangeChapters}
          />

          <PrivateRoute
            exact={true}
            path="/arrangeTopics"
            component={ArrangeTopics}
          />

          <PrivateRoute
            exact={true}
            path="/mock/fillinblanks"
            component={MockFillInBlanks}
          />

          <PrivateRoute
            exact={true}
            path="/mock/matchfollowing"
            component={MatchTheFollowing}
          />

          <PrivateRoute
            exact={true}
            path="/mock/multichoicemultianswers"
            component={MockMcq}
          />

          <PrivateRoute
            exact={true}
            path="/mock/classification"
            component={MockClassification}
          />

          <PrivateRoute
            exact={true}
            path="/mock/arrange"
            component={MockArrAange}
          />

          <PrivateRoute
            exact={true}
            path="/notifications"
            component={Notifications}
          />
          <PrivateRoute exact={true} path="/banners" component={Banner} />
          <PrivateRoute exact={true} path="/students" component={StudentList} />

          <PrivateRoute exact={true} path="/queries" component={Queries} />
          <PrivateRoute exact={true} path="/schools" component={Schools} />
          <PrivateRoute exact={true} path="/stories" component={Stories} />

          <PrivateRoute
            exact={true}
            path="/transactionList"
            component={Transactions}
          />

          <PrivateRoute
            exact={true}
            path="/vavcategories"
            component={VavCategories}
          />

          <PrivateRoute
            exact={true}
            path="/Paidviacash"
            component={PaidviaCash}
          />

          <PrivateRoute exact={true} path="/coupons" component={Coupon} />
        </Suspense>
      </Switch>
    </div>
  );
}

export default App;
