import React from "react";
import HandleLogout from "./Logout";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory } from "react-router-dom";

function Header({ title }) {
  return (
    <div className="classSelection__header">
      <GoToBack />
      <h1>{title}</h1>
      <HandleLogout />
    </div>
  );
}

function GoToBack() {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };

  const style = {
    display: "flex",
    alignItems: "center",
    color: "white",
    fontWeight: "600",
    cursor: "pointer",
  };

  return (
    <div className="goToBack" onClick={() => handleClick()} style={style}>
      <KeyboardBackspaceIcon />
      <h4 style={{ marginLeft: "10px" }}>Back</h4>
    </div>
  );
}

export default Header;

export function HeaderWithNoBack({ title }) {
  return (
    <div className="classSelection__header">
      <h1>{title}</h1>
    </div>
  );
}
