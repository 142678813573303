const password = "cherri2054";

function confirmDelete() {
  const confirmPassword = window.prompt("Enter password to take action:");
  if (confirmPassword === password) {
    return true;
  }
  if (confirmPassword !== "") {
    alert("Wrong password");
  }
  return false;
}

export default confirmDelete;
