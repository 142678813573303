import axios from "../../Axios";

export async function fileUpload(file) {
  if (!navigator.onLine) {
    alert("You are offline. Please connect to internet to submit");
    return;
  }
  if (!file) return;
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios({
      url: "/v1/auth/file",
      method: "POST",
      data: formData,
    });
    if (response.data) {
      return response.data.fileUrl;
    }
    return "";
  } catch (error) {
    alert(error.message);
    return "";
  }
}

export async function videoFileUpload(file) {
  if (!navigator.onLine) {
    alert("You are offline. Please connect to internet to submit");
    return;
  }
  if (!file) return;
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios({
      url: "/v1/auth/file/space",
      method: "POST",
      data: formData,
    });
    if (response.data) {
      return response.data.fileUrl;
    }
    return "";
  } catch (error) {
    alert(error.message);
    return "";
  }
}

export async function MultipleFileUpload(files) {
  let filesUrl = [];
  files.forEach(async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios({
        url: "/v1/auth/file",
        method: "POST",
        data: formData,
      });
      if (response.data) {
        filesUrl.push(response.data.fileUrl);
      }
    } catch (error) {
      alert(error.message);
    }
  });
  return filesUrl;
}

export async function deleteFile(filePath) {
  if (!filePath) return;
  try {
    const response = await axios({
      url: "/v1/auth/file/space",
      method: "DELETE",
      data: { filePath },
    });
    if (response.data) {
      return response.data;
    }
    return "";
  } catch (error) {
    alert(error.message);
    return "";
  }
}
