export const labelValueGenerator = (data) => {
  const newData = data.map((value) => {
    return {
      value,
      label: value,
    };
  });

  return newData;
};
