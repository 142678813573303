import React from "react";
import Switch from "react-switch";

const ReactToggle = (title, value, setMethod) => (
  <div
    style={{
      display: "flex",
      margin: "1rem 0",
      marginLeft: "1rem",
      color: "#fff",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: "1rem",
    }}
  >
    <span style={{ marginRight: "0.5rem", minWidth: "10vw" }}>{title} :</span>
    <Switch onChange={() => setMethod(!value)} checked={value} />
  </div>
);

export default ReactToggle;
