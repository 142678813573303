import React from "react";
import { ActionTypes } from "../../AppStore/constants";
import { useDispatch } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";

const actionLogoutDispatcher = (dispatch) => ({
  setLogout: (logout) =>
    dispatch({
      type: ActionTypes.USER_LOGOUT,
      payload: logout,
    }),
});

export default function HandleLogout() {
  const { setLogout } = actionLogoutDispatcher(useDispatch());
  const history = useHistory();
  const handleLogout = () => {
    const logout = window.confirm("Do you want to log out of your account?");
    if (logout) {
      localStorage.removeItem("p_teacher_auth_token");
      localStorage.removeItem("p_teacher_refresh_token");
      setLogout(false);
      history.push("/login");
    }
  };
  return (
    <div className="logout" onClick={() => handleLogout(history)}>
      <ExitToAppIcon />
      <h4>Log Out</h4>
    </div>
  );
}

export const handleLogoutOnTokenExpire = () => {
  const { setLogout } = actionLogoutDispatcher(useDispatch());
  const history = useHistory();
  localStorage.removeItem("p_teacher_auth_token");
  localStorage.removeItem("p_teacher_refresh_token");
  setLogout(false);
  history.push("/login");
};
