/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./ImagePreview.css";

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
    };
  }

  render() {
    const { photoIndex } = this.state;

    console.log(this.props)

    return (
      <div className="preview">
        {this.props.openPhoto && (
          <Lightbox
            mainSrc={this.props.images[photoIndex]}
            nextSrc={
              this.props.images[(photoIndex + 1) % this.props.images.length]
            }
            prevSrc={
              this.props.images[
                (photoIndex + this.props.images.length - 1) %
                  this.props.images.length
              ]
            }
            onCloseRequest={() => this.props.setOpenPhoto(false)}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + this.props.images.length - 1) %
                  this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default ImagePreview;
