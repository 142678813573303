export const ActionTypes = {
  SET_CLASSES: "SET_CLASSES",
  SET_BOARDS: "SET_BOARDS",
  SET_MEDIUMS: "SET_MEDIUMS",
  SET_SUBJECTS: "SET_SUBJECTS",
  SET_CLASSID: "SET_CLASSID",
  SET_BOARDID: "SET_BOARDID",
  SET_MEDIUMID: "SET_MEDIUMID",
  SET_SUBJECTID: "SET_SUBJECTID",
  SET_UNITS: "SET_UNITS",
  SET_UNIT: "SET_UNIT",
  SET_CHAPTERS: "SET_CHAPTERS",
  SET_CHAPTER: "SET_CHAPTER",
  SET_TOPICS: "SET_TOPICS",
  SET_TOPIC: "SET_TOPIC",
  USER_LOGOUT: "USER_LOGOUT",
  SET_ACTIVITYFORPREVIEW: "SET_ACTIVITYFORPREVIEW",
  SET_LISTOFACTIVITIES: "SET_LISTOFACTIVITIES",
  SET_ACTIVITYFOREDIT: "SET_ACTIVITYFOREDIT",
  SET_SELECTED_COURSE: "SET_SELECTED_COURSE",
  SET_SELECT_SUBJECT_TYPE: "SET_SELECT_SUBJECT_TYPE",
  SET_SELECT_SUBJECT_TYPES: "SET_SELECT_SUBJECT_TYPES",
  SET_SELECTED_SCOPES: "SET_SELECTED_SCOPES",
};
