import { createStore } from "redux";
import { persistStore } from "redux-persist";

import activities from "./AppStore/reducer";

export const store = createStore(
  activities,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);

// export default { store, persistor };
