import React, { useEffect, useState } from "react";
import "../AddBanner/AddBanner.css";
import Header from "../../utils/Header";
import ReactToggle from "../../utils/ReactToggle";
import { Button } from "@material-ui/core";
import { imageMaxSize } from "../../utils/maxSize";
import { imageExt } from "../../utils/fileExtension";
import { FilePicker } from "react-file-picker";
import { fileUpload } from "../../utils/fileUpload";
import axios from "../../../Axios";
import { toast, ToastContainer } from "react-toastify";
import ImagePreview from "../../utils/ImagePreview";
import { labelValueGenerator } from "../../utils/labelValueGenerator";
import { multiSelectContainer } from "../AddBanner/AddBanner";

function EditBanner({ setOpen, setBannerReload, bannerData }) {
  console.log(bannerData)
  const [imageUrl, setImageUrl] = useState(
    bannerData.imageUrl ? bannerData.imageUrl : ""
  );
  const [enabled, setEnabled] = useState(
    bannerData.enabled ? bannerData.enabled : false
  );

  const [openPhoto, setOpenPhoto] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const [classes, setClasses] = useState([]);
  const [boards, setBoards] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [classNo, setClassNo] = useState(
    labelValueGenerator(bannerData.grades)
  );
  const [board, setBoard] = useState(labelValueGenerator(bannerData.boards));
  const [medium, setMedium] = useState(labelValueGenerator(bannerData.mediums));

  useEffect(() => {
    const ac = new AbortController();
    (async () => {
      try {
        const response = await axios({
          url: "/v1/auth/syllabus",
          method: "GET",
        });
        if (response) {
          response.data.grades.unshift("all");
          setClasses(labelValueGenerator(response.data.grades));
          setBoards(labelValueGenerator(response.data.boards));
          setMediums(labelValueGenerator(response.data.medium));
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("p_teacher_auth_token");
          localStorage.removeItem("p_teacher_refresh_token");
          history.push("/login");
        }
      }
    })();
    return ac.abort();
  }, []);

  const handleSave = async () => {
    let grades = classNo.map((item) => item.value);
    let boards = board.map((item) => item.value);
    let mediums = medium.map((item) => item.value);

    const data = {
      link:
        typeof imageUrl === "string" ? imageUrl : await fileUpload(imageUrl),
      grades,
      boards,
      mediums,
      startDate: "not required",
      endDate: "not required",
      enabled,
    };
    try {
      const result = await axios({
        method: "PUT",
        url: `/v1/auth/carousalBanner/${bannerData._id}`,
        data: data,
      });
      if (result) {
        toast.success("Banner Updated");
        setTimeout(() => {
          setOpen(false);
          setBannerReload(true);
        }, 3000);
      }
    } catch (e) {
      toast.error("error updating the banner");
    }
  };

  const handleOpenPhoto = (imageUrl) => {
    setOpenPhoto(true);
    setPhotoUrl(imageUrl);
  };

  return (
    <div className="addBanner">
      <Header title="Edit Banner" />
      <div className="addBannerContainer">
        <div className="mockTestContainer__top">
          {inputContainer("Link", imageUrl, setImageUrl)}
        </div>
        <div className="mockTestContainer__top">
          <div className="mockTestContainer__top">
            {fileContainer("Upload Image", "Image", imageUrl, setImageUrl)}
            <img
              className="preview__image"
              src={
                typeof imageUrl === "string"
                  ? imageUrl
                  : URL.createObjectURL(imageUrl)
              }
              alt="img"
              onClick={() =>
                handleOpenPhoto(
                  typeof imageUrl === "string"
                    ? imageUrl
                    : URL.createObjectURL(imageUrl)
                )
              }
            />
          </div>
        </div>
        <div className="classSelector">
          {multiSelectContainer("Grades", classes, classNo, setClassNo)}
          {multiSelectContainer("Boards", boards, board, setBoard)}
          {multiSelectContainer("Mediums", mediums, medium, setMedium)}
        </div>
        <div className="mockTestContainer__top">
          {ReactToggle("Enable Banner", enabled, setEnabled)}
        </div>
      </div>
      <Button onClick={() => handleSave()}>Save</Button>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {openPhoto && (
        <ImagePreview
          openPhoto={openPhoto}
          setOpenPhoto={setOpenPhoto}
          images={[photoUrl]}
        />
      )}
    </div>
  );
}

export default EditBanner;

const inputContainer = (title, value, setValue) => (
  <div className="title">
    <label>{title}</label>
    <input
      type="text"
      placeholder={`Add ${title} about content`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);

const fileContainer = (title, type, value, setValue) => (
  <div className="fileContainer">
    <p>{title}</p>
    <div>
      <FilePicker
        maxSize={imageMaxSize}
        extensions={imageExt}
        onChange={(FileObject) => setValue(FileObject)}
        onError={(errMsg) => alert(errMsg)}
      >
        <button>
          <i className="fas fa-cloud-upload-alt"></i>Upload {type}
        </button>
      </FilePicker>
    </div>
    {value && <p>{value.name}</p>}
  </div>
);
