import { combineReducers } from "redux";
import { ActionTypes } from "./constants";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const intialState = {
  classes: [],
  boards: [],
  mediums: [],
  subjects: [],
  classId: null,
  boardId: null,
  mediumId: null,
  subjectId: null,
  units: [],
  unit: null,
  chapters: [],
  chapter: null,
  topics: [],
  topic: null,
  activityForPreview: null,
  listOfActivities: [],
  activityForEdit: null,
  selectedCourse: null,
  selectSubjectType: null,
  selectSubjectTypes: [],
  scopes: [],
};

const activityReducer = (state = intialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CLASSES:
      return {
        ...state,
        classes: action.payload,
      };
    case ActionTypes.SET_BOARDS:
      return {
        ...state,
        boards: action.payload,
      };
    case ActionTypes.SET_MEDIUMS:
      return {
        ...state,
        mediums: action.payload,
      };
    case ActionTypes.SET_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
      };
    case ActionTypes.SET_CLASSID:
      return {
        ...state,
        classId: action.payload,
      };
    case ActionTypes.SET_BOARDID:
      return {
        ...state,
        boardId: action.payload,
      };
    case ActionTypes.SET_MEDIUMID:
      return {
        ...state,
        mediumId: action.payload,
      };
    case ActionTypes.SET_SUBJECTID:
      return {
        ...state,
        subjectId: action.payload,
      };
    case ActionTypes.SET_UNITS:
      return {
        ...state,
        units: action.payload,
      };
    case ActionTypes.SET_UNIT:
      return {
        ...state,
        unit: action.payload,
      };
    case ActionTypes.SET_CHAPTERS:
      return {
        ...state,
        chapters: action.payload,
      };
    case ActionTypes.SET_CHAPTER:
      return {
        ...state,
        chapter: action.payload,
      };
    case ActionTypes.SET_TOPICS:
      return {
        ...state,
        topics: action.payload,
      };
    case ActionTypes.SET_TOPIC:
      return {
        ...state,
        topic: action.payload,
      };
    case ActionTypes.SET_ACTIVITYFORPREVIEW:
      return {
        ...state,
        activityForPreview: action.payload,
      };
    case ActionTypes.SET_LISTOFACTIVITIES:
      return {
        ...state,
        listOfActivities: action.payload,
      };
    case ActionTypes.SET_ACTIVITYFOREDIT:
      return {
        ...state,
        activityForEdit: action.payload,
      };
    case ActionTypes.USER_LOGOUT:
      return {
        undefined,
        unit: action.payload,
      };
    case ActionTypes.SET_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
      };
    case ActionTypes.SET_SELECT_SUBJECT_TYPE:
      return {
        ...state,
        selectSubjectType: action.payload,
      };
    case ActionTypes.SET_SELECT_SUBJECT_TYPES:
      return {
        ...state,
        selectSubjectTypes: action.payload,
      };
    case ActionTypes.SET_SELECTED_SCOPES:
      return {
        ...state,
        scopes: action.payload,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["activityReducer"],
};

const rootReducer = combineReducers({
  activityReducer,
});

export default persistReducer(persistConfig, rootReducer);
