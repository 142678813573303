import React from "react";
import Select from "react-select";
import "./SelectClassBar.css";

// const groupStyles = {
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "space-between",
// };
// const groupBadgeStyles = {
//   backgroundColor: "#EBECF0",
//   borderRadius: "2em",
//   color: "#172B4D",
//   display: "inline-block",
//   fontSize: 12,
//   fontWeight: "normal",
//   lineHeight: "1",
//   minWidth: 1,
//   padding: "0.16666666666667em 0.5em",
//   textAlign: "center",
// };

// const formatGroupLabel = (data) => (
//   <div style={groupStyles}>
//     <span>{data.label}</span>
//     <span style={groupBadgeStyles}>{data.options.length}</span>
//   </div>
// );

// const IndicatorsContainer = (props) => {
//   return (
//     <div
//       style={{
//         background: "#06015b",
//         border: "3px solid #06015",
//         borderTopRightRadius: "7px",
//         borderBottomRightRadius: "7px",
//       }}
//     >
//       <components.IndicatorsContainer {...props} />
//     </div>
//   );
// };

const customStyles = {
  option: (provided) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    textAlign: "center",
    fontFamily: "font-family: 'Roboto', sans-serif;",
    //color: state.isSelected ? "red" : "#f0a077",
    padding: 10,
    zindex: 999,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: "1px solid #c0c0c0",
    background: "#fff",
    display: "flex",
    borderRadius: "7px",
    width: 250,
    cursor: "pointer",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  //   menu: () => ({
  //     borderRadius: "20px",
  //     backgroundColor: "#c0c0c0",
  //     marginTop: "5px",
  //   }),
};

// eslint-disable-next-line react/prop-types
const MultiSelectBar = ({ data, value, setValue }) => {
  const handleSelect = (e) => {
    setValue(e);
  };
  return (
    <Select
      isMulti
      className="select"
      defaultValue={value}
      options={data}
      name="colors"
      //   components={{ IndicatorsContainer }}
      value={value}
      styles={customStyles}
      onChange={handleSelect}
      // isClearable={clear}
      // formatGroupLabel={formatGroupLabel}
    />
  );
};

export default MultiSelectBar;
