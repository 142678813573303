import React from "react";
import { Route } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
        // localStorage.getItem("p_teacher_auth_token") ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect to="/login" />
        // )
      }
    />
  );
}

export default PrivateRoute;
