import React, { useState } from "react";
import "./ReArrangeBanner.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "../../../Axios";
import { Button } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../utils/Header";
import ImagePreview from "../../utils/ImagePreview";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function ReArrangeBanner({ banners, setOpen, setBannerReload }) {
  const [arrangedBanners, setArrangedBanners] = useState(banners);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(
      arrangedBanners,
      result.source.index,
      result.destination.index
    );
    setArrangedBanners(items);
  };

  const handleSave = async () => {
    let newArr = [];
    arrangedBanners.forEach((banner, index) => {
      let data = {
        bannerId: banner._id,
        bannerNumber: index + 1,
      };
      newArr.push(data);
    });
    try {
      const result = await axios({
        method: "PUT",
        url: "/v1/auth/carousalbanner/edit/rearrange",
        data: newArr,
      });
      if (result) {
        setTimeout(() => {
          setBannerReload(true);
          setOpen(false);
        }, 3000);
        toast.success("updated");
      }
    } catch (error) {
      toast.success("error rearranging data");
    }
  };

  const handleOpenPhoto = (imageUrl) => {
    setOpenPhoto(true);
    setPhotoUrl(imageUrl);
  };

  return (
    <div
      className="arrangeUnits"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="arrangeUnits__container">
        <Header title="Re Arrange Banner" />
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="arrangeUnitsContainer__BottomRight">
            {(provided) => (
              <ul
                className="arrangeUnitsContainer__items"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {arrangedBanners.map((banner, index) => (
                  <Draggable key={index} draggableId={index + ""} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <img
                          className="image"
                          src={banner.imageUrl}
                          alt="img"
                          onClick={() => handleOpenPhoto(banner.imageUrl)}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Button
        style={{
          marginTop: "2rem",
          width: "15vw",
          marginLeft: "1rem",
          padding: "10px 30px",
          marginRight: "1rem",
          cursor: "pointer",
          border: "none",
          outlineWidth: 0,
          display: "flex",
          alignItems: "center",
          borderRadius: "7px",
          color: "#fff",
          backgroundColor: "#fcaa87",
        }}
        onClick={() => handleSave()}
      >
        Save
      </Button>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {openPhoto && (
        <ImagePreview
          openPhoto={openPhoto}
          setOpenPhoto={setOpenPhoto}
          images={[photoUrl]}
        />
      )}
    </div>
  );
}

export default ReArrangeBanner;
