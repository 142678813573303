/* eslint-disable no-unused-vars */
import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "../utils/Header";
import "./Banner.css";
import "../MockTest/MockTest.css";
import Zoom from "react-reveal/Zoom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import axios from "../../Axios";
import ImagePreview from "../utils/ImagePreview";
import AddBanner from "./AddBanner/AddBanner";
import { ToastContainer, toast } from "react-toastify";
import EditBanner from "./EditBanner/EditBanner";
import ReArrangeBanner from "./ReArrange/ReArrangeBanner";
import confirmDelete from "../utils/confirmDelete";

const customStyles = {
  content: {
    height: "100vh",
    width: "95vw",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};

const actionLogoutDispatcher = (dispatch) => ({
  setLogout: (logout) =>
    dispatch({
      type: "USER_LOGOUT",
      payload: logout,
    }),
});

function Banner() {
  const [banners, setBanners] = useState([]);
  const [photoUrl, setPhotoUrl] = useState("");

  //modals
  const [openPhoto, setOpenPhoto] = useState(false);
  const [addBanner, setAddBanner] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [reArrangeBanners, setReArrangeBanners] = useState(false);

  const [bannerReload, setBannerReload] = useState(false);

  // dispatch methods
  const { setLogout } = actionLogoutDispatcher(useDispatch());
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const ac = new AbortController();
    (async () => {
      try {
        const result = await axios({
          method: "GET",
          url: "/v1/auth/media?mediaType=Banner",
        });
        console.log(result)
        if (result) {
          setBanners(result.data);
        }
      } catch (e) {
        toast.error("Error fetching banner");
      }
    })();
    setBannerReload(false);
    return ac.abort();
  }, [bannerReload === true]);

  const handleAddBanner = () => {
    setAddBanner(true);
  };

  const handleReArrangeBanner = () => {
    setReArrangeBanners(true);
  };

  const handleEditBanner = (banner) => {
    setEditBanner(true);
    setBannerData(banner);
  };

  const handleDeleteBanner = async (id) => {
    const value = confirmDelete();
    if (!value) return;
    const a = confirm("Do you want to delete this banner?");
    if (!a) return;
    try {
      const result = await axios({
        method: "DELETE",
        url: `/v1/auth/media/${id}`,
      });
      if (result) {
        toast.success("Successfully deleted");
        setBannerReload(true);
      }
    } catch (e) {
      toast.error("Error deleting banner");
    }
  };

  const handleOpenPhoto = (imageUrl) => {
    setOpenPhoto(true);
    setPhotoUrl(imageUrl);
  };

  return (
    <div className="banner">
      <Header title="Banner" />
      <div className="classSelector">
        {buttonContainer("Add Banner", handleAddBanner)}
        {buttonContainer("Re Arrange Banners", handleReArrangeBanner)}
      </div>
      <div className="bannerContainer">
        {banners.map((banner, index) => (
          <div key={index} className="mockTest__row">
            <label>{index + 1}</label>
            <img
              className="image"
              src={banner.imageUrl !== "" ? banner.imageUrl : banner.link}
              alt="img"
              onClick={() => handleOpenPhoto(banner.imageUrl !== "" ? banner.imageUrl : banner.link)}
            />
            <p>{banner.grades.toString()}</p>
            <p>{banner.boards.toString()}</p>
            <p>{banner.mediums.toString()}</p>
            <p onClick={() => handleEditBanner(banner)}>Edit Banner</p>
            <p onClick={() => handleDeleteBanner(banner._id)}>Delete Banner</p>
          </div>
        ))}
      </div>
      {addBanner && openModal(addBanner, setAddBanner, setBannerReload)}
      {reArrangeBanners &&
        ReArrangeModal(
          reArrangeBanners,
          setReArrangeBanners,
          setBannerReload,
          banners
        )}
      {editBanner &&
        openEditModal(
          editBanner,
          setEditBanner,
          setBannerReload,
          bannerData,
          setBannerData
        )}
      {openPhoto && (
        <ImagePreview
          openPhoto={openPhoto}
          setOpenPhoto={setOpenPhoto}
          images={[photoUrl]}
        />
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export const buttonContainer = (buttonLabel, handleClick) => (
  <div className="buttonContainer">
    <Button onClick={() => handleClick()}>{buttonLabel}</Button>
  </div>
);

const openModal = (open, setOpen, setBannerReload) => {
  return (
    <Modal isOpen={true} style={customStyles}>
      <Zoom>
        <button onClick={() => setOpen(false)} className="close-modal">
          x
        </button>
        <AddBanner setOpen={setOpen} setBannerReload={setBannerReload} />
      </Zoom>
    </Modal>
  );
};

const ReArrangeModal = (open, setOpen, setBannerReload, banners) => {
  return (
    <Modal isOpen={true} style={customStyles}>
      <Zoom>
        <button onClick={() => setOpen(false)} className="close-modal">
          x
        </button>
        <ReArrangeBanner
          setOpen={setOpen}
          setBannerReload={setBannerReload}
          banners={banners}
        />
      </Zoom>
    </Modal>
  );
};

const openEditModal = (
  open,
  setOpen,
  setBannerReload,
  bannerData,
  setBannerData
) => {
  return (
    <Modal isOpen={true} style={customStyles}>
      <Zoom>
        <button onClick={() => setOpen(false)} className="close-modal">
          x
        </button>
        <EditBanner
          setOpen={setOpen}
          setBannerReload={setBannerReload}
          bannerData={bannerData}
          setBannerData={setBannerData}
        />
      </Zoom>
    </Modal>
  );
};

export default Banner;
